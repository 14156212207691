import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import AuthContext from "context/auth/authContext"
import { HelveticaInput } from "common/inputs"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import { YellowButton } from "common/buttons"
import DropdownSelectQuestion1 from "./DropdownSelectQuestion1"
import DropdownSelectQuestion2 from "./DropdownSelectQuestion2"
import { CircularProgress } from "@mui/material"
import DropdownSelectQuestion3 from "./DropdownSelectQuestion3"
import { Auth } from "aws-amplify"
import DropdownSelectCountry from "./DropdownSelectCountry"

const OnboardingSurvey = ({}) => {
  const { user, setForm, getUser, updateUser, submitForm } =
    useContext(AuthContext)
  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])
  const history = useHistory()

  const referenciasInput = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]

  const [values, setValues] = useState({
    email: user?.email,
    name: "",
    surname: "",
    phone: "",
    //organization:"",
    role: "",
    questions: [
      {
        id: "q1",
        question: "¿Cuál es tu objetivo con Planhopper? ",
        answer: "",
      },

      {
        id: "q3",
        question: "¿De cuántos miembros consta tu equipo de trabajo?",
        answer: "",
      },
    ],
  })

  const onChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const [clicked, setClicked] = useState(false)
  const onSubmit = async () => {
    if (validated) {
      setLoading(true)
      await submitForm({
        ...values,
        email: user.email,
        phone: prefijo.dial_code + " " + values.phone,
      })

      setLoading(false)
      history.push("/dashboard")
    } else {
      setClicked(true)
      referenciasInput.forEach((ref, index) => {
        if (index <= 2) {
          if (!ref.current.value) {
            ref.current.style.border = "1px solid #BF2929"
          } else {
            ref.current.style.border = "1px solid #C4C4C4"
          }
        }
      })
      if (values.role.length == 0) {
        referenciasInput[3].current.style.color = "#BF2929"
      } else {
        referenciasInput[3].current.style.color = "#414141"
      }
      if (!values.questions[0].answer) {
        referenciasInput[4].current.style.color = "#BF2929"
      } else {
        referenciasInput[4].current.style.color = "#414141"
      }

      if (!values.questions[1].answer) {
        referenciasInput[5].current.style.color = "#BF2929"
      } else {
        referenciasInput[5].current.style.color = "#414141"
      }
    }
  }

  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [prefijo, setPrefijo] = useState({ emoji: "🇪🇸", dial_code: "+34" })

  useEffect(() => {
    if (
      values.name &&
      values.surname &&
      values.phone &&
      values.role &&
      values.questions[0].answer
    ) {
      setValidated(true)
    } else {
      setValidated(false)
    }
  }, [values])

  const setProfesion = value => {
    setValues({ ...values, role: value })
  }

  const setValue0 = value => {
    setValues({
      ...values,
      questions: [
        {
          ...values.questions[0],
          answer: value,
        },
        values.questions[1],
      ],
    })
  }

  const setValue1 = value => {
    setValues({
      ...values,
      questions: [
        values.questions[0],
        {
          ...values.questions[1],
          answer: value,
        },
      ],
    })
  }

  return user ? (
    <div
      className="divContainerHubspot"
      style={{
        justifyContent: "center",
        display: "flex",
        width: "100%",
        zIndex: "999",
      }}
    >
      <div
        className="autoVariableWidth"
        style={{ width: "45%", marginLeft: "30px" }}
      >
        <div
          id="BlockHubspot"
          className="inline"
          style={{ width: "100%", position: "relative" }}
        >
          <div
            className="div100"
            style={{ width: "50%", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Nombre*")}
            </HelveticaBold>
            <HelveticaInput
              ref={referenciasInput[0]}
              className="div100"
              style={{ width: "90%" }}
              name="name"
              value={values.name}
              onChange={onChange}
              placeholder={i18n.t("Nombre")}
            />
          </div>
          <div
            className="div100"
            style={{ width: "50%", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Apellidos*")}
            </HelveticaBold>
            <HelveticaInput
              ref={referenciasInput[1]}
              className="div100"
              style={{ width: "90%" }}
              name="surname"
              value={values.surname}
              onChange={onChange}
              placeholder={i18n.t("Apellidos")}
            />
          </div>
        </div>

        <div
          id="BlockHubspot"
          className="inline"
          style={{ width: "100%", position: "relative" }}
        >
          <div
            className="div100"
            style={{ width: "50%", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Teléfono ☎️*")}
            </HelveticaBold>
            <DropdownSelectCountry value={prefijo} setValue={setPrefijo} />
            <HelveticaInput
              ref={referenciasInput[2]}
              className="div100"
              style={{ width: "90%", paddingLeft: "100px" }}
              name="phone"
              value={values.phone}
              onChange={onChange}
              placeholder={i18n.t("Teléfono")}
            />
          </div>
          <div
            className="div100"
            style={{ width: "50%", position: "relative" }}
          >
            <HelveticaBold
              ref={referenciasInput[3]}
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Profesión 🧰*")}
            </HelveticaBold>
            <DropdownSelectQuestion3
              value={values.role}
              setValue={setProfesion}
              referencia={referenciasInput[4]}
            />
          </div>
        </div>

        <div className="inline" style={{ width: "100%", position: "relative" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <HelveticaBold
              ref={referenciasInput[4]}
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("¿Cuál es tu objetivo con Planhopper? 🎯*")}
            </HelveticaBold>
            <DropdownSelectQuestion2
              value={values.questions[0].answer}
              setValue={setValue0}
              referencia={referenciasInput[5]}
            />
          </div>
        </div>

        <div className="inline" style={{ width: "100%", position: "relative" }}>
          <div style={{ width: "100%", position: "relative" }}>
            <HelveticaBold
              ref={referenciasInput[5]}
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "0px",
              }}
            >
              {i18n.t("¿De cuántos miembros consta tu equipo de trabajo? *")}
            </HelveticaBold>
            <DropdownSelectQuestion1
              value={values.questions[1].answer}
              setValue={setValue1}
              referencia={referenciasInput[5]}
            />
          </div>
        </div>

        {!validated && clicked && (
          <HelveticaBold
            style={{ fontSize: "14px", color: "#BF2929", fontWeight: "400" }}
          >
            {i18n.t(
              "*Todos los campos son obligatorios, por favor, rellena todos los campos",
            )}
          </HelveticaBold>
        )}
        <div
          className="inline"
          style={{
            width: "100%",
            position: "relative",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <YellowButton
              onClick={e => {
                onSubmit(e)
              }}
            >
              <HelveticaBold
                style={{
                  fontWeight: "700",
                  fontSize: "14px",
                  color: "#414141",
                  marginBottom: "0px",
                }}
              >
                {i18n.t("ENVIAR")}
              </HelveticaBold>
            </YellowButton>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default OnboardingSurvey
